import { Application } from '@hotwired/stimulus';
import '@hotwired/turbo-rails';
import tippy from 'tippy.js';

import { Tab } from 'bootstrap/dist/js/bootstrap.esm';
import SidePanelController from './controllers/side_panel_controller';
import PieChartController from './controllers/charts/pie_chart_controller';
import TreemapChartController from './controllers/charts/tree_map_chart_controller';
import PricingSliderController from './controllers/pricing_slider_controller';

const handleTooltips = () => {
  tippy('[data-tooltip]', {
    content: (reference) => reference.dataset.tooltip,
  });
};

document.documentElement.addEventListener('turbo:load', handleTooltips);

document.documentElement.addEventListener('turbo:load', () => {
  // Restart Brevo Conversations
  console.log('turbo load');
  handleTooltips();
  window.BrevoConversations('pageView');
  window.BrevoConversations('restart');
});

const application = Application.start();

application.register('charts--pie-chart', PieChartController);
application.register('charts--tree-map-chart', TreemapChartController);
application.register('side-panel', SidePanelController);
application.register('pricing-slider', PricingSliderController);
